/*
*	Автор RnD SoftLab (Davydov Lev aka S@DLer), 2015 - 2019.
*	Дата последнего изменения: 28.09.2019 09:35
*	Система: SimpleONG system.
*	GlobalSystemVersion: 0.7.1.1
*/

//////////////////
// Init Shadowbox.
Shadowbox.init(
{
	handleOversize: "resize",
	overlayOpacity: 0.8,
	modal: false
});

//////////////////////////////
// Подключаем модуль роутинга.
Vue.use(VueRouter);
